<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <h4 class="card-title font-weight-bold" style='font-size: 30px'>Editar Categoría</h4>
          </template>
          <form class='px-4' style='height: auto;' @submit.prevent="saveCategoria">
            <div class="row pt-4 align-items-center">
              <div class='col-12 col-md-6 pb-3 pb-md-0'>
                <label for="Nombre">Nombre *</label>
                <b-form-input id="Nombre" name='Nombre' required placeholder="Ingrese Nombre" trim
                              :value='saveCategoriaobjet.Categoria.Nombre'></b-form-input>
              </div>
            </div>
            <div class='pt-3'>
              <label for="Descripcion">Descripción</label><br/>
              <b-form-textarea rows="6" class='p-3' id='Descripcion' name='Descripcion'
                               placeholder="Ingrese Descripción" required
                               :value='saveCategoriaobjet.Categoria.Descripcion'></b-form-textarea>
            </div>
            <div class="d-flex flex-column pt-5 pb-2">
              <h4 class='mb-2' style='font-size: large;'>Impuestos</h4>
              <p class='w-100' style='font-size: 13px;'>Los impuestos seran calculados en base al costo del
                articulo, y se van a ver reflejados en la factura</p>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>
            <vs-table>
              <template #thead>
                <vs-tr style='background: var(--body-bg) !important'>
                  <vs-th style='min-width: 270px !important;  background: var(--body-bg) !important'>
                    Impuesto
                  </vs-th>
                  <vs-th style='min-width: 280px !important;  background: var(--body-bg) !important'>
                    Descripcion
                  </vs-th>
                  <vs-th style='min-width: 10rem;  background: var(--body-bg) !important'>
                    Porcentaje
                  </vs-th>
                  <vs-th style='min-width: 10rem;  background: var(--body-bg) !important'>
                    Acciones
                  </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr v-for="(data, index) in saveCategoriaobjet.Impuestos" :key="index">
                  <vs-td>
                    <b-form-select name='Impuesto' v-model="saveCategoriaobjet.Impuestos[index].Impuesto"
                                   :options='listaImpuestos' :value-field="'ID'" :text-field="'TextoLabel'"
                                   @change="updateDescripcion(index)"
                                   placeholder='-- Seleccionar un Impuesto --' required>
                      <template #selected="{ option }">
                        {{ option?.Impuesto || '-- Seleccionar un Impuesto --' }}
                        <!-- Muestra solo el diminutivo seleccionado -->
                      </template>
                      <template #first>
                        <b-form-select-option value="null" disabled>-- Seleccionar un Impuesto --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </vs-td>
                  <vs-td>{{ data.Descripcion }}</vs-td>
                  <vs-td>
                    <b-form-input type="number" id="Porcentaje" name='PorcentajeImpuesto'
                                  :disabled="saveCategoriaobjet.Impuestos[index].Impuesto==null"
                                  v-model="saveCategoriaobjet.Impuestos[index].Porcentaje"
                                  aria-describedby="input-live-help input-live-feedback"
                                  placeholder="Ingrese Porcentaje" required trim>
                    </b-form-input>
                  </vs-td>
                  <vs-td>
                    <vs-button class='bg-danger' @click.prevent="eliminarImpuesto(index)">
                      <ion-icon name="trash-outline"></ion-icon>
                    </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
              <template #footer>
                <div class='d-flex justify-content-center'>
                  <vs-button class='d-flex' style='gap: 1rem;' primary @click.prevent="agregarImpuesto">
                    <ion-icon
                        style='font-size: 1.6rem' name="add-outline"></ion-icon>
                    Agregar Impuesto
                  </vs-button>
                </div>
              </template>
            </vs-table>
            <div class="d-flex flex-column pt-5 pb-2">
              <h4 class='mb-2' style='font-size: large;'>Descuentos</h4>
              <p class='w-100' style='font-size: 13px;'>Los Descuentos seran calculados en base al costo del
                articulo, y se veran reflejados en la factura</p>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>
            <vs-table>
              <template #thead>
                <vs-tr style='background: var(--body-bg) !important'>
                  <vs-th style='min-width: 270px !important;  background: var(--body-bg) !important'>
                    Descuento
                  </vs-th>
                  <vs-th style='min-width: 280px !important;  background: var(--body-bg) !important'>
                    Descripcion
                  </vs-th>
                  <vs-th style='min-width: 10rem;  background: var(--body-bg) !important'>
                    Porcentaje
                  </vs-th>
                  <vs-th style='min-width: 10rem;  background: var(--body-bg) !important'>
                    Acciones
                  </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr v-for="(data, index) in saveCategoriaobjet.Descuentos" :key="index">
                  <vs-td>
                    <b-form-select name='Impuesto' v-model="saveCategoriaobjet.Descuentos[index].Descuento"
                                   :options='listaDescuentos' :value-field="'ID'" :text-field="'Descuento'"
                                   @change="updateDescripcionDescuento(index)"
                                   placeholder='-- Seleccionar un Impuesto --' required>
                      <template #selected="{ option }">
                        {{ option?.Impuesto || '-- Seleccionar un Impuesto --' }}
                      </template>
                      <template #first>
                        <b-form-select-option value="null" disabled>-- Seleccionar un Impuesto --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </vs-td>
                  <vs-td>{{ data.Descripcion }}</vs-td>
                  <vs-td>
                    {{ saveCategoriaobjet.Descuentos[index].Porcentaje }} %
                  </vs-td>
                  <vs-td>
                    <vs-button class='bg-danger' @click.prevent="eliminarDescuento(index)">
                      <ion-icon name="trash-outline"></ion-icon>
                    </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
              <template #footer>
                <div class='d-flex justify-content-center'>
                  <vs-button class='d-flex' style='gap: 1rem;' primary @click.prevent="agregarDescuento">
                    <ion-icon
                        style='font-size: 1.6rem' name="add-outline"></ion-icon>
                    Agregar Descuento
                  </vs-button>
                </div>
              </template>
            </vs-table>
            <div class='d-flex pt-4 justify-content-end'>
              <vs-button size="large" :loading="waiting">Guardar</vs-button>
              <vs-button size="large" class='bg-danger'
                         @click.prevent="$router.push('/helex/Erp/Contabilidad/Categorias')">Cancelar
              </vs-button>
            </div>
          </form>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  data: () => ({
    waiting: false,
    saveCategoriaobjet: {
      Categoria:{
        Id: null,
        Nombre: null,
        Descripcion: null,
      },
      Descuentos: [],
      Impuestos: []
    },
    listaDescuentos: [],
    listaImpuestos: []
  }),
  async mounted() {
    const valor = JSON.parse(localStorage.getItem('setPersonaAct'));
    if (valor) {
      this.saveCategoriaobjet.EmpresaId = valor.empresa.id;
      this.EmpresaId = valor.empresa.id;
    }
    await this.GetCategoria();
  },
  methods: {
    async GetCategoria() {
      await this.$store.dispatch('hl_get', {
        path: 'CONTArticulo/ObtenerImpuestos'
      }).then(response => {
        if (response.respuesta) {
          this.listaImpuestos = response.data.map(item => ({
            ID: item.ID,
            Impuesto: item.Impuesto,
            Descripcion: item.Descripcion,
            TextoLabel: `${item.Impuesto} // ${item.Descripcion}`
          }))
        }
      })
      await this.$store.dispatch('hl_get', {
        path: 'ContDescuentos/ObtenerListaDescuentosEmpresaId/' + this.EmpresaId,
      }).then(response => {
        if (response.respuesta) {
          this.listaDescuentos = response.data;
        } else {
          this.$vs.notification({
            position: 'top-center',
            title: 'Error',
            text: response.mensaje,
            border: 'danger'
          })
        }
      })
      await this.$store.dispatch("hl_get", {
        path: 'CONTCategoria/GetCategoriaById/' + this.$route.params.id,
      }).then(res => {
        if (res.respuesta) {
          this.saveCategoriaobjet = res.data;
        }else{
          this.$vs.notification({
            position: 'top-center',
            title: 'Error',
            text: res.mensaje,
            border: 'danger'
          })
        }
      })
    },
    async saveCategoria() {
      try {
        this.waiting = true;
        const response = await this.$store.dispatch('hl_post',{
          path: `CONTCategoria/EditarCategoria/${this.$route.params.id}`,
          data: this.saveCategoriaobjet
        });
        if (response.respuesta) {
          Swal.fire({
            title: 'Editar Cuenta',
            text: 'Se ha editado la Cuenta',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.$router.push('/helex/Erp/Contabilidad/Categorias');
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: response.mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'No se ha editado la Cuenta',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      } finally {
        this.waiting = false;
      }
    },
    updateDescripcionDescuento(index) {
      const selectedId = this.saveCategoriaobjet.Descuentos[index].Descuento;
      const selectedImpuesto = this.listaDescuentos.find(descuento => descuento.ID === selectedId);

      this.saveCategoriaobjet.Descuentos[index].Descripcion = selectedImpuesto
          ? selectedImpuesto.Descripcion : '';
      this.saveCategoriaobjet.Descuentos[index].Porcentaje = selectedImpuesto
          ? selectedImpuesto.Porcentaje : '';
    },
    agregarDescuento() {
      this.saveCategoriaobjet.Descuentos.push({
        Descuento: null,
        Descripcion: null,
        Porcentaje: 0
      });
    },
    eliminarDescuento(index) {
      this.saveCategoriaobjet.Descuentos.splice(index, 1);
    },
    agregarImpuesto() {
      this.saveCategoriaobjet.Impuestos.push({
        Impuesto: null,
        Descripcion: null,
        Porcentaje: 0
      });
    },
    eliminarImpuesto(index) {
      this.saveCategoriaobjet.Impuestos.splice(index, 1);
    },
    updateDescripcion(index) {
      const selectedId = this.saveCategoriaobjet.Impuestos[index].Impuesto;
      const selectedImpuesto = this.listaImpuestos.find(impuesto => impuesto.ID === selectedId);
      this.saveCategoriaobjet.Impuestos[index].Descripcion = selectedImpuesto ? selectedImpuesto.Descripcion : '';
    }
  }
}
</script>

<style>
.vs-select__input {
  background-color: transparent !important;
  border: 1px solid var(--vs-colors--dark) !important;
  width: 100%;
}

.vs-select-content {
  max-width: 50%;
}

.vs-input {
  width: 100%;
  border-radius: 7px;
}
</style>